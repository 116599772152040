import './src/theme/destyle.css'
import './src/theme/global.css'

import { ThemeProvider } from './src/theme'
import { Transition } from './src/utils/transition'

const transitionDelay = 800

export const wrapPageElement = ({ element, props }) => {
  return (
    <ThemeProvider {...props}>
      <Transition {...props}>{element}</Transition>
    </ThemeProvider>
  )
}

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)

  return false
}
